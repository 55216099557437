<template>
  <header-component></header-component>
  <background :start-positions="startPositions"/>
  <div class="resp-content">
    <section>
      <h1 class="resp-title">Le numérique responsable</h1>
      <p>
        En tant que développeur, mon objectif est de créer des applications éco-responsables et accessibles. Je suis conscient de l'impact environnemental du secteur numérique, contribuant à environ 2 % des émissions mondiales de gaz à effet de serre, selon l'Agence internationale de l'Énergie. De plus, la production croissante de déchets électroniques, estimée à 52,2 millions de tonnes d'ici 2021 par l'ONU, souligne l'urgence d'agir. C'est pourquoi je m'engage à concevoir des applications les plus numériquement responsable possible. Pour moi, le numérique responsable ne se limite pas à l'aspect environnemental, mais inclut également l'accessibilité, garantissant que mes créations puissent être utilisées par tous, sans exclusion.
      </p>
    </section>
    <section>
      <h2>Formation comme moteur de changement</h2>
      <p>
        Ma formation que j'ai suivie au sein du BUT Informatique Graphique à l'IUT du Puy-en-Velay a été moteur pour moi en ce qui concerne mon envie de développer des applications respectant les normes d’écoconception. Durant ma formation, j’ai pu participer à de nombreux moments ayant permis de me sensibiliser à cette cause, tels que l’atelier "2tonnes" ou bien une conférence de Fairphone. En plus de ces moments de sensibilisation, la formation continue que nous recevons nous apprend les meilleures pratiques en matière de numérique responsable.
      </p>
    </section>
    <section>
      <h2>Principes du développement web responsable</h2>
      <p>
        Dans le domaine du développement web responsable, j'applique des principes simples mais cruciaux. Par exemple, j'opte pour des images légères pour réduire la consommation de bande passante et attribue des balises descriptives pour garantir l'accessibilité aux personnes atteintes de déficiences visuelles. Éviter les animations superflues ou les fonctionnalités non essentielles contribue également à la réduction de la consommation d'énergie et de ressources.
      </p>
    </section>
    <section>
      <h2>Exemple concret : "Hide & Street"</h2>
      <p>
        Un exemple de cet engagement est le projet "Hide & Street", une application de jeu de cache-cache repensée. Développée avec Flutter pour le frontend et Node.js avec PostgreSQL pour le backend, elle intègre les principes du Green Code pour réduire son empreinte environnementale. De plus, elle est conçue pour être accessible à tous, notamment aux personnes atteintes de déficiences visuelles, grâce à un système de son permettant de jouer en utilisant des indications audio. En adoptant de telles initiatives, nous contribuons à rendre le web plus respectueux de l'environnement et accessible à tous.
      </p>
    </section>
    <div class="resp-links">
      <a href="/article/hide-and-street">Voir le projet</a>
      <a href="mailto:dorianrochette@gmail.com"> Me contacter</a>
    </div>
  </div>
  <footer-component></footer-component>
  <background :start-positions="startPositions"/>
</template>

<script>
import Background from '@/components/CircleBg.vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';

export default {
  name: 'NumeriqueResponsable',
  components: {
    HeaderComponent,
    FooterComponent,
    Background
  },
  data() {
    return {
      startPositions: [{ x: 50, y: 300 }, { x: 600, y: 80 }, { x: 900, y: 500 }],
    };
  }
};
</script>

<style scoped>
.resp-links {
  display: flex;
  justify-content: center;
  gap: 2em;
  margin-top: 5%;
}

.resp-links a {
  background-color: #181D3E;
  border-radius: 100px;
  border: none;
  font-size: 1.5em;
  color: white;
  padding: 15px 30px;
}

.resp-content {
  margin: 5% 5%;
}

.resp-title {
  font-size: 3.5em;
  margin-bottom: 1em;
  text-align: center;
}

.resp-content p
{
  font-size: 2em;
}
.resp-content h2
{
  margin-top: 5%;
  margin-bottom: 2%;
  font-size: 2.5em;
}
</style>
