<template>
  <div class="button-section">
    <button @click="sendEmail" class="button-article button">{{ content }}</button>
    </div>
  </template>

  <script>
  export default {
    props: {
    content: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
    },
    methods: {
    sendEmail() {
      const mailtoLink = 'mailto:dorianrochette@gmail.com';
      window.location.href = mailtoLink;
    }
    }
  };
  </script>

<style scoped>
.button-section {
  text-align: center;
  margin-top: 5%;
}

.button-article {
  font-size: 1.5em;

}

</style>
