<template>
    <div class="subtitle-section">
      <h3 class="article-subtitle">{{ content }}</h3>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      content: String
    }
  };
  </script>
  
  <style scoped>
   .subtitle-section {
    margin-top: 10%;
    margin-right: 14%;
    margin-left: 14%;
    margin-bottom: 5%;
  }
  
  .article-subtitle {
    font-size: 3em;
    color: black;
    text-align: center;
  }
  </style>
  