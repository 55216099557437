<template>
  <div>
    <h2 class="subtitle wide-letter">MES PROJETS</h2>
    <div>
      <div class="project-grid">
        <div
          v-for="(project, index) in displayedProjects"
          :key="index"
          class="project-card"
          :style="{ backgroundImage: 'url(' + project.image + ')' }"
          @click="navigateToProject(project.name)"
          @mouseover="showTooltip('cliquez pour en voir +', $event)"
          @mousemove="updateTooltipPosition($event)"
          @mouseleave="hideTooltip"
        >
          <div class="project-overlay">
            <p class="project-title">{{ project.title }}</p>
          </div>
        </div>
      </div>
      <div class="cta-container">
        <a href="mailto:dorianrochette@gmail.com" 
          class="a-cta" 
          @mouseover="showTooltip('Ça vaut le coup 😉', $event)"
          @mousemove="updateTooltipPosition($event)"
          @mouseleave="hideTooltip"
          
        >
          ME CONTACTER
        </a>
        <a v-if="projects.length > itemsToShow" @click="showMore" class="text-link">→ afficher +</a>
      </div>
    </div>
    <tooltip-component ref="tooltip"></tooltip-component>
  </div>
</template>

<script>
import TooltipComponent from './TooltipComponent.vue';

export default {
  components: {
    TooltipComponent
  },
  props: {
    projects: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      itemsToShow: 8
    };
  },
  computed: {
    displayedProjects() {
      return this.projects.slice(0, this.itemsToShow);
    }
  },
  methods: {
    navigateToProject(name) {
      this.$router.push({ name: 'Article', params: { name } });
    },
    showMore() {
      this.itemsToShow += 6;
    },
    showTooltip(text, event) {
      this.$refs.tooltip.showTooltip(text, event);
    },
    hideTooltip() {
      this.$refs.tooltip.hideTooltip();
    },
    updateTooltipPosition(event) {
      this.$refs.tooltip.updatePosition(event);
    }
  }
};
</script>

<style scoped>
.a-cta{
  background-color: #181D3E;
  border-radius: 100px;
  border: none;
  color: white;
  padding: 15px 30px;
}

.project-grid {
  margin-left: 5%;
  margin-right: 5%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 40px;
}

.project-card {
  position: relative;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-position: center;
  cursor: pointer;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  transition: transform 0.3s ease;
}

.cta-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  gap: 90px;
  align-items: center;
  font-size: 1.5rem;
}

.button-cta {
  font-size: 1.2rem;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-card::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(28, 31, 61, 0), rgba(28, 31, 61, 0.2), rgba(28, 31, 61, 1));
}

.project-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  color: #fff;
}

.project-title {
  font-size: 1.2rem;
  color: white;
}

.show-more-button:hover {
  background-color: #2c2f5a;
}

h2 {
  margin-top: 50px;
  margin-bottom: 120px;
  font-size: 2.5rem;
}
</style>
