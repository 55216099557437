<template>
    <footer>
        <h3 class="copyright">dorian rochette&copy;</h3>
        <div class="footer-link">
            <a href="mailto:dorianrochette@gmail.com"><img src="../assets/img/email.png" alt="icon of email click for contact"></a>
            <a href="https://www.linkedin.com/in/dorian-rochette/" target="_blank"><img src="../assets/img/linkedin.png" alt="icon of email click for contact"></a>
        </div>
        
    </footer>
</template>

<style>
    footer{
        height: 5vh;
        display: flex;
        font-size: 1.2em;
        align-items: end;
        justify-content: space-between;
        padding: 1% 5%;
    }

    .footer-link{
        display: flex;
        gap: 20px;
    }

    .footer-link img{
        width: 40px;
        height: 40px;
    }
</style>
