<template>
    <div class="image-section">
      <img :src="image" class="article-image-big" :alt="altText" />
    </div>
  </template>
  
  <script>
  export default {
    props: {
      image: String,
      altText: String
    }
  };
  </script>
  
  <style scoped>
  .image-section {
    margin-top: 3%;
    margin-right: 14%;
    margin-left: 14%;
  }

  .article-image-big {
    width: 100%;
    height: auto;
    border-radius: 25px;
    border: 1px solid black;
  }
  </style>
  