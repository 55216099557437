<template>
    <div class="youtube-player-section">
      <iframe
        :src="youtubeUrl"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      videoId: {
        type: String,
        required: true
      }
    },
    computed: {
      youtubeUrl() {
        return `https://www.youtube.com/embed/${this.videoId}`;
      }
    }
  };
  </script>
  
  <style scoped>
  .youtube-player-section {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    margin-left: 14%;
    margin-right: 14%;
  }

  .youtube-player-section iframe {
    width: 100%;
    height: 600px;
  }
  
  </style>
  