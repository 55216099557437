import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ArticleView from '../views/ArticleView.vue';
import ResponsibleView from '../views/ResponsibleView.vue';
import articlesData from '@/assets/scripts/articlesLoader.js';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      title: 'Portfolio Dorian Rochette',
    },
  },
  {
    path: '/article/:name',
    name: 'Article',
    component: ArticleView,
    meta: {
      title: 'Portfolio Dorian Rochette - Article',
    },
  },
  {
    path: '/numerique-responsable',
    name: 'NumeriqueResponsable',
    component: ResponsibleView,
    meta: {
      title: 'Portfolio Dorian Rochette - Numérique Responsable',
    },
  },
  {
    path: '/cv',
    name: 'Cv',
    beforeEnter() {
      // Open the PDF in a new tab
      window.open('/CV Dorian Rochette.pdf', '_blank');
      return false; // Prevents navigation to the route
    },
    meta: {
      title: 'Portfolio Dorian Rochette - CV',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    meta: {
      title: 'Portfolio Dorian Rochette - Page Not Found',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Global title update for all routes
router.beforeEach((to) => {
  if (to.name === 'Article') {
    const article = articlesData.find(article => article.name === to.params.name);
    document.title = article ? `Portfolio Dorian Rochette - ${article.title}` : to.meta.title;
  } else {
    document.title = to.meta.title || 'Portfolio Dorian Rochette';
  }
});

export default router;
